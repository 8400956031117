'use client'

import { Close, SettingsOutlined } from '@mui/icons-material'
import { Box, Hidden, Stack, SvgIcon } from '@mui/material'
import { useRouter } from 'next/navigation'
import { useCallback, useState } from 'react'
import { Trans } from 'react-i18next'
import { HomePageComponent } from '~/features/home/pageComponents/home'
import { Colors } from '~/libs/mui/theme'
import { PageWithHeader } from './_components'

type Props = {
  searchParams?: {
    utm_medium?: string
    utm_source?: string
    utm_campaign?: string
    utm_content?: string
    utm_term?: string
  }
}

const LOCAL_STORAGE_KEY = '2024_payment'

function HomePage({ searchParams }: Props): JSX.Element {
  const { push } = useRouter()
  const [showPaymentNotice, setShowPaymentNotice] = useState(
    window.localStorage.getItem(LOCAL_STORAGE_KEY) === null && new Date() < new Date('2024-12-01'),
  )

  const closePaymentNotice = useCallback(() => {
    setShowPaymentNotice(false)
    window.localStorage.setItem(LOCAL_STORAGE_KEY, 'showed')
  }, [])

  return (
    <PageWithHeader
      titleI18nKey='home'
      mobileLeftContent={
        <SvgIcon
          component={SettingsOutlined}
          sx={{
            color: Colors.color5A5957,
          }}
          onClick={() => push('/setting')}
        />
      }
    >
      {showPaymentNotice && (
        <Stack
          direction='row'
          color={Colors.color0D0D0E}
          sx={{ border: '1px solid #43A047', backgroundColor: '#E8F5E9' }}
        >
          <Stack
            direction='row'
            gap={1}
            justifyContent='center'
            padding={2}
            width='100%'
            sx={{ cursor: 'pointer' }}
            onClick={() => push('https://about.samansa.com/post/payment')}
          >
            <Hidden smDown>
              <Box>
                <Trans i18nKey='important_notice' />
              </Box>
            </Hidden>
            <Box>
              <Trans i18nKey='monthly_fee_revision_effective_december_1_2024' />
            </Box>
            <Box sx={{ textDecoration: 'underline' }}>
              <Trans i18nKey='more_details' />
            </Box>
          </Stack>
          <Box padding={2} sx={{ cursor: 'pointer' }} onClick={closePaymentNotice}>
            <SvgIcon component={Close} />
          </Box>
        </Stack>
      )}
      <HomePageComponent searchParams={searchParams} />
    </PageWithHeader>
  )
}

export default HomePage
