import { type GetKeyVisualsQuery } from '~/__generated__/graphql'

export interface ScreenPopupModel {
  id: string
  title: string
  image: string
  rowOrderRank: number
  buttonInfo?: {
    text: string
    color: string
    textColor: string
    redirectUrl: string
  }
  videoId?: string
}

export interface KeyVisualModel {
  id: string
  title: string
  image: string
  buttonInfo?: {
    text: string
    color: string
    textColor: string
    redirectUrl: string
  }
  videoInfo?: {
    id: string
    minutes: number
    seconds: number
    description: string
  }
}

/**
 * apolloが生成した型からネストしている型を抽出する。 (TODO: 理想はクエリ側をFragmentで書いて、その型を使う)
 * NOTE: 基本的に下記の型はファイル内に閉じる
 */
type ExtractEdgesType<T> = T extends { edges?: Array<infer E> | null } ? E : never
type ExtractNodeType<T> = T extends { node?: infer E } ? E : never
type ExtractButtonType<T> = T extends { button?: infer E } ? E : never
type ExtractVideoType<T> = T extends { video?: infer E } ? E : never

type EdgesValueType = ExtractEdgesType<GetKeyVisualsQuery['screenPopups']>
type NodeValueType = ExtractNodeType<EdgesValueType>
type ButtonValueType = NonNullable<ExtractButtonType<NodeValueType>>
type VideoValueType = NonNullable<ExtractVideoType<ButtonValueType>>

export function createButtonInfo(buttonInfo: ButtonValueType): ScreenPopupModel['buttonInfo'] {
  return {
    text: buttonInfo.text || '',
    color: buttonInfo.color || '',
    textColor: buttonInfo.textColor || '',
    redirectUrl: buttonInfo.url || '',
  }
}

export function createVideoInfo(videoInfo: VideoValueType): KeyVisualModel['videoInfo'] {
  return {
    id: videoInfo.id,
    minutes: videoInfo.duration?.minutes || 0,
    seconds: videoInfo.duration?.seconds || 0,
    description: videoInfo.description || '',
  }
}
